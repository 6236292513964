import React, { useContext, useState } from 'react';
import { withRouter } from 'next/router';
import PropTypes from 'prop-types';
import { useQuery, useSubscription, useApolloClient } from '@apollo/client';
import Article from '../components/News/Article';
import { useSiteContext } from '../components/SiteContext';
import withApollo from '../lib/apollo';
import throw404 from '../lib/throw404';
import articleByPathQuery from '../lib/queries/news/articleByPathQuery.graphql';
import { articleImageConfigurations } from '../lib/imageConfigurations';
import redirectToRoute from '../lib/redirectToRoute';
import getNewsboard from '../lib/getNewsboard';
import getArticlesParameters from '../lib/getArticlesParameters';
import { getRedirectLocation } from '../components/Redirect';
import getPrefix from '../lib/getPrefix.mjs';
import getUrlFromRequest from '../lib/getUrlFromRequest.mjs';
import getWindowUrl from '../lib/getWindowUrl.mjs';
import { UserContext } from '../components/UserProvider';
import OrderStatusNotification
from '../components/News/Article/OrderStatusNotification';
import orderStatusQuery
from '../lib/queries/dashboard/orderStatusQuery.graphql';
import Loader from '../components/Loader';
import orderUpdatedSubscription
from '../lib/subscriptions/orderUpdatedSubscription.graphql';
import { OrderStatus } from '../lib/graphql/api/graphql';

const NewsArticle = ({ router }) => {
  const [orderStatus, setOrderStatus] = useState();

  const { hasLocalNews } = useSiteContext();
  const { user } = useContext(UserContext);

  const {
    query: {
      id,
      orderId,
      categoryPath,
      slug,
      insertedAt,
    },
  } = router;

  const apolloClient = useApolloClient();

  useSubscription(orderUpdatedSubscription, {
    skip: !orderId || orderStatus === OrderStatus.PAID,
    variables: { id: Number(orderId) },
    onData: ({ data: { data: { orderUpdated: { status: newStatus } } } }) => {
      setOrderStatus(newStatus);

      if (newStatus === OrderStatus.PAID) {
        apolloClient.refetchQueries({ include: ['Article'] });
      }
    },
  });

  const { loading, error } = useQuery(orderStatusQuery, {
    skip: !orderId,
    variables: { orderId: Number(orderId) },
    onCompleted: (data) => {
      const status = data.order?.status;
      setOrderStatus(status);
    },
  });

  if (loading) return <Loader fullScreen />;

  if ((!id && !hasLocalNews) || error) {
    return throw404();
  }

  const articleProperties = {
    id: Number(id),
  };

  const handleNotificationDismissed = () => {
    redirectToRoute('article', {
      id,
      categoryPath,
      slug,
      insertedAt,
    });
  };

  return (
    <>
      {orderStatus && user && (
        <OrderStatusNotification
          orderStatus={orderStatus}
          handleNotificationDismissed={handleNotificationDismissed}
        />
      )}
      <Article {...articleProperties} orderStatus={orderStatus} />
    </>
  );
};

NewsArticle.propTypes = {
  router: PropTypes.shape({
    query: PropTypes.shape({
      id: PropTypes.string,
      orderId: PropTypes.string,
      categoryPath: PropTypes.string,
      slug: PropTypes.string,
      insertedAt: PropTypes.string,
    }),
  }),
};

NewsArticle.defaultProps = {
  router: {},
};

NewsArticle.getInitialProps = async (context) => {
  const {
    query: { id }, apolloClient, req, res, asPath,
  } = context;

  if (!id) {
    const url = req ? getUrlFromRequest(req) : getWindowUrl();
    const path = asPath.split('?')[0].replace(/^\//, '');

    if (!req || req?.headers['x-redirect-test'] !== 'true') {
      const redirectLocation = await getRedirectLocation(url);

      if (redirectLocation) {
        return redirectToRoute(redirectLocation, {}, res);
      }
    }

    const newsboard = getNewsboard(getPrefix(url));

    const {
      data: {
        universalNewsArticleByPath: article,
      },
    } = await apolloClient.query({
      query: articleByPathQuery,
      variables: {
        newsboard,
        path,
        imageConfigurations: articleImageConfigurations,
      },
      fetchPolicy: 'network-only',
    });

    if (!article && res?.status) {
      return throw404();
    }

    const articleParameters = getArticlesParameters(
      article,
    );

    return redirectToRoute('article', {
      ...articleParameters,
    }, res);
  }

  return {};
};

export const NewsArticleWithRouter = withRouter(NewsArticle);

NewsArticleWithRouter.getLayout = (page) => page;

export default withApollo(NewsArticleWithRouter);
